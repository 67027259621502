import { useEffect, useState } from "react";
import { FormProvider } from "../../contexts/CreateRecordFormContext";
import RecordModal from "../../components/RecordModal/RecordModal";
import UsersConfig from "../../lib/config/users";
import Feedback from "../../components/Feedback/Feedback";
import { API } from "../../lib/api";
import ResponsiveColumns from "./ResponsiveColumns";
import { AddCircle, Construction } from "@mui/icons-material";
import ActionButtons from "../../components/ActionButtons/ActionButtons";
import DataTable from "../../components/DataTable/DataTable";

const Content = () => {
    const [users, setUsers] = useState([]) 
    const [selectedRows, setSelectedRows] = useState([]);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""}) 

    const columns = ResponsiveColumns()

    const addUser = (newUser) => setUsers(prevUsers => [...prevUsers, newUser]);

    // Keep track of selected rows
    const handleSelectionChange = (selection) => setSelectedRows(selection)

    const handleOpenCreateModal = () => setOpenCreateModal(true);
    const handleCloseCreateModal = () => setOpenCreateModal(false);
      
    const handleOpenUpdateModal = () => selectedRows.length ? setOpenUpdateModal(true) : setFeedback({open: true, message: "Select at least one record to update!"})
    const handleCloseUpdateModal = () => setOpenUpdateModal(false);


    // API Request to Create a User
    const handleCreateSubmit = async (data) => {
        const response = await API.Users.Create(data)

        if (response.status === 200) {
            addUser(response.data.data)
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: 'success' }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: 'error' }))
        }

        handleCloseCreateModal()
    };

    // API Request to Update a User
    const handleUpdateSubmit = async (userData) => {

        let temp = []
        
        let updatedFields = {}
        Object.keys(userData).forEach(key => {
            if (userData[key]) {
                Object.assign(updatedFields, { [key]: userData[key] } )
            }
        });
        
        for (let i=0; i<selectedRows.length; i++) {
            temp.push({_id: selectedRows[i], data: updatedFields })
        }

        const response = await API.Users.Update(temp)

        if (response.status === 200) {
            setUsers(prevUsers =>
                prevUsers.map(user => {
                    const update = temp.find(update => update._id === user._id);
                    return update ? { ...user, ...update.data } : user;
                })
            );
            setFeedback(prevData => ({...prevData, open: true, message: response.data.message, type: 'success' }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response?.data?.message || "Error updating user(s)", type: 'error' }))
        }
        
        handleCloseUpdateModal(false) 
    };

    // API Request to Delete a user
    const handleDeleteSubmit = async () => {
        const response = await API.Users.Delete(selectedRows)
        if (response.status === 200) {
            setUsers(prevUsers =>
                prevUsers.filter(user => !selectedRows.includes(user._id))
            );
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: 'success' }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: 'error' }))
        }

        setSelectedRows([]) 
        handleCloseUpdateModal()
    }

    // Define buttons for ActionButtons component
    const actionButtons = [
        { title: "Create User", onClick: handleOpenCreateModal, icon: AddCircle, color: "primary" },
        { title: "Update Selected Record(s)", onClick: handleOpenUpdateModal, icon: Construction, color: "primary" },
    ];

    useEffect(() => {
        async function getData() {
            const response = await API.Users.FetchAll()
            setUsers(response)
        }
        getData()
    },[])

    return (
        <FormProvider>
            <Feedback props={feedback} setFeedback={setFeedback} />
            <ActionButtons buttons={actionButtons} />
            <RecordModal open={openCreateModal} handleClose={handleCloseCreateModal} fields={UsersConfig.createUserFields} title="Create New User" onSubmit={handleCreateSubmit} />
            <RecordModal open={openUpdateModal} handleClose={handleCloseUpdateModal} fields={UsersConfig.updateUserFields} title="Update User" onSubmit={handleUpdateSubmit} onDelete={handleDeleteSubmit} isUpdate={true} />
            <DataTable rows={users} columns={columns} handleSelectionChange={handleSelectionChange} />
        </FormProvider>        
    )
}

export default Content