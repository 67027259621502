import { Box, Modal, Step, StepLabel, Stepper, Typography, useMediaQuery } from "@mui/material"
import dayjs from 'dayjs'; // Ensure you have dayjs installed for date formatting

const LogsModal = ({logs, open, handleClose}) => {
    const isMobile = useMediaQuery('(max-width:600px)');
    const isLaptop = useMediaQuery('(max-width:1280px)')

    const style = {
        position: 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: isMobile ? '85vw' : isLaptop ? '60vw' : '30vw',
        bgcolor: 'background.paper',
        border: '2px solid #5b6b79',
        borderRadius: "10px",
        boxShadow: 24,
        p: 4,
        overflowY: 'auto', // Scroll if needed
        maxHeight: '80vh' // Prevents overflowing the viewport
    }

    const CustomStepIcon = ({ icon }) => {
        return (
          <Box
            sx={{
              width: 24,
              height: 24,
              borderRadius: '50%',
              backgroundColor: '#1976d2',
              color: '#fff',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              fontSize: 12,
              fontWeight: 'bold'
            }}
          >
            {icon}
          </Box>
        );
      };

    return (
        <Modal open={open} onClose={handleClose}>
            <Box sx={style}>
                <Typography variant="h6" component="h2">Opportunity logs</Typography>
                <Stepper activeStep={0} orientation="vertical">

                {logs.map((log, index) => {
                    const displayNumber = logs.length - index;

                    return (
                        <Step key={log._id}>
                            <StepLabel StepIconComponent={() => <CustomStepIcon icon={displayNumber} />}>
                                <Typography variant="body1">{log.description}</Typography>
                                <Typography variant="caption" color="textSecondary">
                                    {dayjs(log.createdOn).format('MMMM D, YYYY [at] h:mm A')}
                                </Typography>
                                <Box sx={{ mb: 0.5 }}>
                                    <Typography variant="caption" color="textSecondary">
                                        {`${log.user.firstName} ${log.user.lastName}`}
                                    </Typography>
                                </Box>
                            </StepLabel>
                        </Step>
                    );
                })}

                </Stepper>
            </Box>
        </Modal>
    )
}

export default LogsModal