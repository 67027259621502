const TableHeaders = () => {
    const headers = ["Name", "Assigned To", "Customer", "Due Date", "Priority", "Status"]
    
    return (
        <thead>
            <tr>
                {headers.map(header => (
                    header === "Status" ? <th key={header} className="text-end">{header}</th> : <th key={header}>{header}</th>
                ))}
            </tr>
        </thead>
    )
}

export default TableHeaders