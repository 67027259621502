import { useEffect, useState } from "react"
import MyQuotesTable from "./Quotes/MyQuotesTable"
import MyQuotesChart from "./Quotes/MyQuotesChart"
import { API } from "../../lib/api";
import { useAuth } from "../../contexts/AuthContext";

const Content = () => {
    const [quotes, setQuotes] = useState([])
    const { user } = useAuth();
    
    useEffect(() => {
        async function getData() {
            const response = await API.Opportunities.FetchByUser(user.id)
            setQuotes(response)
        }
        getData()
        console.log("Dashboard:", user)
    }, [user])
    return (
        <div className="row">
            <MyQuotesTable quotes={quotes} />
            <MyQuotesChart quotes={quotes} />
        </div>
    )    
}

export default Content