import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
const OppsByStatus = ({ opportunities, organizationName }) => {
    const [sortedQuotes, setSortedQuotes] = useState([])

    const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name: 'Quotes By Status',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 16,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: sortedQuotes
            }
        ]
    }

    useEffect(() => {
        if (opportunities.length > 0) {
            // Step 1: Initialize an empty object to keep track of counts
            const statusCounts = {};
    
            // Step 2: Iterate through the array and count occurrences of each status
            opportunities.forEach(quote => {
                if (statusCounts[quote.salesStage]) {
                    statusCounts[quote.salesStage]++;
                } else {
                    statusCounts[quote.salesStage] = 1;
                }
            });
    
            // Step 3: Convert the counts object into the desired array format
            const result = Object.keys(statusCounts).map(salesStage => ({
                value: statusCounts[salesStage],
                name: salesStage,
            }));
    
            setSortedQuotes(result)
        }
      }, [opportunities]);

    return (
        <div className="col-lg-5">
            <div className="card" style={{maxHeight: "400px"}}>
                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5>Open Opportunities By Stage - <a href="#">{organizationName}</a></h5>
                </div>
                <div className="card-body">
                    <ReactECharts
                        option={option}
                        style={{ height: '300px' }}
                        opts={{renderer: 'svg'}} // use svg to render the chart.
                    />
                </div>
            </div>
        </div>
    )
}

export default OppsByStatus