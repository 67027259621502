import { AdminPanelSettingsOutlined, LockOpenOutlined, SecurityOutlined } from '@mui/icons-material';
import { Box, Typography, useMediaQuery } from '@mui/material';

const ResponsiveColumns = () => {
    // Define the breakpoints
    const isMobile = useMediaQuery('(max-width:600px)');

    return [
        {
            field: "firstName",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: ({row: { firstName, lastName }}) => `${firstName} ${lastName}`
        },
        // Conditionally show/hide columns
        ...(isMobile ? [] : [
            {
                field: "email",
                headerName: "Email",
                flex: 1,
            },
            {
                field: "department",
                headerName: "Department",
                flex: 1,
            },
        ]),
        {
            field: "role",
            headerName: "Role",
            flex: 1,
            renderCell: ({ row: { role } }) => {
                return (
                    <Box
                        width={`${isMobile ? '80%' : '60%'}`}
                        m="0 auto"
                        p="5px"
                        display="flex"
                        justifyContent="center"
                        backgroundColor={
                            role === "admin" ?
                            "#3da58a" :
                            role === "manager" ? "#c53939"
                            : role === "lead" ? "#3ec9d6"
                            : "#4680ff"
                        }
                        borderRadius="4px"
                    >
                        {role === "admin" && <AdminPanelSettingsOutlined />}
                        {role === "manager" && <SecurityOutlined />}
                        {role === "user" && <LockOpenOutlined />}
                        <Typography color={"#e0e0e0"} sx={{ ml: "5px" }}>
                            {role}
                        </Typography>
                    </Box>
                );
            },
        }, 
    ];
};

export default ResponsiveColumns