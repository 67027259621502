import SideMenu from "./SideMenu/SideMenu"
import Header from "./Header/Header"
import Footer from "./Footer/Footer"

const BaseWrapper = ({ content }) => {

    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        side.classList.remove("mob-sidebar-active")
    }

    return (
        <>
            <div className="loader-bg">
                <div className="loader-track">
                    <div className="loader-fill"></div>
                </div>
            </div>
            <SideMenu/>
            <Header/>
                <div className="pc-container" onClick={handleSideBar}>
                    <div className="pc-content">
                        {content}
                    </div>
                </div> 
            <Footer/>
        </>
    )
}

export default BaseWrapper