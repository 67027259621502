import axios from 'axios';

const productionServer = process.env.REACT_APP_SERVER_BASE;
// const devServer = process.env.REACT_APP_TEMP_SERVER;

export const API = {

    Users: {

        Create: async (data) => {
            try {
                const response = await axios.post(`${productionServer}/users/new`, data, {
                    withCredentials: true // <-- Key for cookie-based auth
                });
                return response;
            } catch (error) {
                return { status: 400, data: error.response.data };
            }
        },

        Update: async (updates) => {
            try {
                const response = await axios.patch(`${productionServer}/users/multi-update`, { updates }, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return { status: 400, data: error.response?.data || { message: 'Update failed' } };
            }
        },

        UpdateOne: async (id, data) => {
            try {
                const response = await axios.patch(`${productionServer}/users/update/${id}`, data, {
                    withCredentials: true,
                    headers: {
                        "Content-Type": "application/json",
                    },
                });
                return response;
            } catch (error) {
                return {
                    status: error.response?.status || 500,
                    data: error.response?.data || "Request failed",
                };
            }
        },

        Delete: async (ids) => {
            try {
                const response = await axios.delete(`${productionServer}/users/delete`, { 
                    data: { ids: ids },
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return { status: 400, data: error.response?.data || { message: 'Delete failed' } };
            }
        },

        FetchAll: async () => {
            try {
                const response = await axios.get(`${productionServer}/users`, {
                    withCredentials: true // Send cookie automatically
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                return [];
            }
        },

        FetchById: async (id) => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        }

    },

    Customers: {

        Create: async (id, data) => {
            try {
                const response = await axios.post(`${productionServer}/customers/new/${id}`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return error?.response;
            }
        },

        Update: async (updates) => {
            try {
                console.log(updates);
                const response = await axios.patch(`${productionServer}/customers/multi-update`, { updates }, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Update failed" }
                };
            }
        },

        UpdateOne: async (cid, data) => {
            try {
                const response = await axios.patch(`${productionServer}/customers/updateone/${cid}`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Update failed" }
                };
            }
        },

        Delete: async (ids) => {
            try {
                const response = await axios.delete(`${productionServer}/customers/delete`, {
                    data: { ids },
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Delete failed" }
                };
            }
        },

        FetchAll: async () => {
            try {
                const response = await axios.get(`${productionServer}/customers`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },

        FetchById: async (id) => {
            try {
                const response = await axios.get(`${productionServer}/customers/sn/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : {};
            } catch (error) {
                console.log(error);
                return {};
            }
        },

    },

    Opportunities: {

        Create: async (data) => {
            try {
                const response = await axios.post(`${productionServer}/opportunities/new`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Create failed" }
                };
            }
        },

        Update: async (updates) => {
            try {
                const response = await axios.patch(`${productionServer}/opportunities/multi-update`, { updates }, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    data: error.response?.data || { message: "Update failed" },
                    status: 400
                };
            }
        },
      
        UpdateSingle: async (id, payload) => {
            try {
                const response = await axios.patch(`${productionServer}/opportunities/update/${id}`, payload, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                console.log(error);
                return {
                    data: error.response?.data || { message: "Update failed" },
                    status: 400
                };
            }
        },

        Delete: async (ids) => {
            try {
                const response = await axios.delete(`${productionServer}/opportunities/delete`, {
                    data: { ids },
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    data: error.response?.data || { message: "Delete failed" },
                    status: 400
                };
            }
        },

        FetchAll: async () => {
            try {
                const response = await axios.get(`${productionServer}/opportunities`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                return [];
            }
        },

        FetchById: async (id) => {
            try {
                const response = await axios.get(`${productionServer}/opportunities/sn/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },

        FetchByCustomer: async (id) => {
            try {
                const response = await axios.get(`${productionServer}/opportunities/customer/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },
        
        FetchByStatus: async () => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        },

        FetchByUser: async (id) => {
            try {
                const response = await axios.get(`${productionServer}/quotes/user/${id}`, {
                    withCredentials: true
                  });
                return response.status === 200 ? response.data : []
            } catch (error) {
                console.log(error)
                return []
            }
        }

    },

    Tasks: {

        Create: async () => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        },

        Update: async () => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        },

        Delete: async () => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        },

        FetchAll: async () => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        },

        FetchByUser: async (id) => {
            try {
                const response = await axios.get(`${productionServer}/tasks/usr/${id}`)
                return response.status === 200 ? response.data : []
            } catch (error) {
                console.log(error)
                return []
            }
        },

        FetchByOpportunity: async () => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        },

        FetchByCustomer: async () => {
            try {
                // Incomplete API Route
            } catch (error) {
                
            }
        }

    },

    Settings: {

        Update: async (id, payload) => {
            try {
                const response = await axios.patch(`${productionServer}/settings/update/${id}`, payload, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                console.log(error);
                return {
                    status: 400,
                    data: error.response?.data || { message: 'Settings update failed' }
                };
            }
        },

        FetchByUser: async (id) => {
            try {
                const response = await axios.get(`${productionServer}/settings/user/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : {};
            } catch (error) {
                return {};
            }
        },

    },

    Lines: {
        Create: async (oid, data) => {
            try {
                const response = await axios.post(`${productionServer}/lines/new/${oid}`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Create failed" }
                };
            }
        },

        FetchByOpportunity: async (oid) => {
            try {
                const response = await axios.get(`${productionServer}/lines/opp/${oid}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },

        FetchById: async (id) => {
            try {
                const response = await axios.get(`${productionServer}/lines/sn/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : {};
            } catch (error) {
                return {};
            }
        },

        UpdateMulti: async (oid, updates) => {
            try {
                const response = await axios.patch(`${productionServer}/lines/multi-update/${oid}`, { updates }, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    data: error.response?.data || { message: "Update failed" },
                    status: 400
                };
            }
        },

        UpdateSingle: async (id, oid, payload) => {
            try {
                const response = await axios.patch(`${productionServer}/lines/update/${id}?oid=${oid}`, payload, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                console.log(error);
                return {
                    data: error.response?.data || { message: "Update failed" },
                    status: 400
                };
            }
        },   

        Delete: async (ids) => {
            try {
                const response = await axios.delete(`${productionServer}/lines/delete`, {
                    data: { ids },
                    withCredentials: true
                });
                console.log(response);
                return response;
            } catch (error) {
                console.log(error);
                return {
                    data: error.response?.data || { message: "Delete failed" },
                    status: 400
                };
            }
        },
    },

    Contacts: {

        Create: async (cid, data) => {
            try {
                const response = await axios.post(`${productionServer}/contact/new/${cid}`, data, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Create failed" }
                };
            }
        },

        Delete: async (cid, ids) => {
            try {
                const response = await axios.delete(`${productionServer}/contact/delete/${cid}`, {
                    data: { ids },
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 400,
                    data: error.response?.data || { message: "Delete failed" }
                };
            }
        },

        Update: async (updates) => {
            try {
                const response = await axios.patch(`${productionServer}/contact/multi-update`, { updates }, {
                    withCredentials: true
                });
                return response
            } catch (error) {
                return { status: 400, data: error.response.data}
            }
        },

    },

    Reporting: {
        
        FetchCustomerMaster: async () => {
            try {
                const response = await axios.get(`${productionServer}/reporting/customer-master`, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 401,
                    data: error?.response?.data || { message: "Unauthorized" }
                };
            }
        },

        FetchForecastFile: async () => {
            try {
                const response = await axios.get(`${productionServer}/reporting/forecast-file`, {
                    withCredentials: true
                });
                return response;
            } catch (error) {
                return {
                    status: 401,
                    data: error?.response?.data || { message: "Unauthorized" }
                };
            }
        },
    },

    OpportunityLogs: {
        FetchByOppId: async (id) => {
            try {
                const response = await axios.get(`${productionServer}/opportunity-logs/${id}`, {
                    withCredentials: true
                });
                return response.status === 200 ? response.data : [];
            } catch (error) {
                console.log(error);
                return [];
            }
        },
    }

}