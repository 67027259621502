const UsersConfig = {
    // -- Users | Create User | Form Fields -- //
    createUserFields: [
        { name: 'email', label: "Email", type: 'text', required: true },
        { name: "firstName", label: "First Name", type: 'text', required: true },
        { name: "lastName", label: "Last Name", required: true },
        { name: "password", label: "Password", type: 'password', required: true },
        { name: "role", label: "Role", type: 'autocomplete', options: ['admin', 'manager', 'user'], required: true },
        { name: "department", label: "Department", type: 'text' }
    ],

    // -- Users | Update User Modal | Form Fields -- //
    updateUserFields: [
        { name: "email", label: "Email", type: 'text' },
        { name: "firstName", label: "First Name", type: 'text' },
        { name: "lastName", label: "Last Name", type: 'text' },
        { name: "role", label: "Role", type: 'autocomplete', options: ['admin', 'manager', 'user'] },
        { name: "department", label: "Department" }
    ],
}

export default UsersConfig