import { MenuItem, Select, useMediaQuery } from '@mui/material';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns"
import { useParams } from 'react-router-dom';
const ResponsiveColumns = () => {
    // Define the breakpoints
    const isMobile = useMediaQuery('(max-width:600px)');
    const bosOptions = ['01 - Assign Resources', '02 - Submittals Sent', '03 - Submittals Approved', '04 - Shipped', '05 - Installed', '06 - Commissioned', '07 - Revenue Taken', '10 - Closed'];
    const { pid } = useParams();
    return [
        {
            field: "productGroup",
            headerName: "Product Group",
            flex: 1,
        },
        {
            field: "lineComments",
            headerName: "Line Description",
            flex: 1,
            renderCell: ({row: { _id, lineComments } }) => <a href={`/lines/${_id}?oid=${pid}`}>{lineComments}</a>
        },
        // Conditionally show/hide columns
        ...(isMobile ? [] : [
            {
                field: "amount",
                headerName: "Amount",
                flex: 1,
                renderCell: ({ value }) => {
                    if (value == null) return ""; // Handle null/undefined values
                    return new Intl.NumberFormat("en-US", {
                        style: "currency",
                        currency: "USD",
                    }).format(value);
                },
            },
            {
                field: "bookedOrdersStage",
                headerName: "Booked Orders Stage",
                flex: 1,
                editable: true,
                renderEditCell: (params) => {
                    const { value, id, field, api } = params;

                    return (
                        <Select
                            value={value || ""}
                            onChange={(event) => {
                                const newValue = event.target.value;

                                // Trigger the processRowUpdate handler
                                api.setEditCellValue({ id, field, value: newValue });
                                api.stopCellEditMode({ id, field }); // Exit edit mode
                            }}
                            fullWidth
                        >
                            {bosOptions.map((option) => (
                                <MenuItem key={option} value={option}>
                                    {option}
                                </MenuItem>
                            ))}
                        </Select>
                    );
                }
            },
            {
                field: "shipDate",
                headerName: "Ship Date",
                flex: 1,
                editable: true,
                // type: "date",
                // valueGetter: ({ value }) => value ? new Date(value) : null, // Safely converts string to Date
                // valueSetter: ({ value }) => ({ shipDate: value ? value.toISOString() : null }), // Safely converts Date to string
                renderEditCell: (params) => {
                    const { value, id, field, api } = params;
                    console.log(params)
                    let formattedDate = value ? new Date(value) : null
                    return (
                        <LocalizationProvider dateAdapter={AdapterDateFns}>
                            <DatePicker
                                value={formattedDate || null} // Use null if value is undefined
                                onChange={(newDate) => {
                                    api.setEditCellValue({ id, field, value: newDate });
                                    api.stopCellEditMode({ id, field });
                                }}
                                renderInput={(props) => <input {...props} />}
                            />
                        </LocalizationProvider>
                    );
                },
            }
        ]),
    ];
};

export default ResponsiveColumns