import TableHeaders from "./TableHeaders"

const MyQuotesTable = ({ quotes }) => {
    return (
        <div className="col-xl-7 col-md-12 col-lg-10">
            <div className="card table-card">

                <div className="card-header d-flex align-items-center justify-content-between">
                    <h5>My Opportunities</h5>
                    <a href="/opportunities" className="link-primary">View all</a>
                </div>

                <div className="card-body">
                    <div className="table-responsive">
                        <table className="table table-hover table-borderless mb-0">
                            <TableHeaders />
                            <tbody id="projects-table">
                                {quotes.length ? (
                                    quotes.map((quote) => (
                                    <tr key={quote._id}>
                                        <td><a href={`/opportunities/${quote._id}`}>{`${quote.name}`}</a></td>
                                        <td>{quote.assignedTo.firstName} {quote.assignedTo.lastName}</td>
                                        <td><a href={`/customers/${quote.customer._id}`}>{quote.customer.companyName}</a></td>
                                        <td>{quote.dueDate.split("T")[0]}</td>
                                        <td><label className={`badge  ${quote.priority === 'Low' ? 'bg-light-success' : quote.priority === 'Medium' ? 'bg-light-primary' : quote.priority === 'High' ? 'bg-light-warning' : 'bg-light-danger'}`}>{quote.priority}</label></td>
                                        <td className="text-end"><label className={`badge  ${quote.status === 'Overdue' ? 'bg-light-danger' : quote.status === 'In Progress' ? 'bg-light-primary' : quote.status === 'Not Started' ? 'bg-light-secondary' : 'bg-light-warning'}`}>{quote.status}</label></td>
                                    </tr>
                                    ))
                                    ) : (
                                    <tr>
                                        <td colSpan="6">No Opportunities Found</td>
                                    </tr>
                                )}
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </div>        
    )
}

export default MyQuotesTable