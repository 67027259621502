import DataTable from "../../components/DataTable/DataTable"
import ResponsiveColumns from "./ResponsiveColumns"
import ActionButtons from "../../components/ActionButtons/ActionButtons"
import { AddCircle, Construction } from "@mui/icons-material";
import RecordModal from "../../components/RecordModal/RecordModal";
import { useState } from "react";
import ContactsConfig from "../../lib/config/contact";
import { FormProvider } from "../../contexts/CreateRecordFormContext";
import { API } from "../../lib/api";

const Contacts = ({ cid, contacts, setContacts, setFeedback }) => {
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [selectedRows, setSelectedRows] = useState([]); 
    const handleSelectionChange = (selection) => setSelectedRows(selection)

    const handleOpenCreateModal = () => setOpenCreateModal(true);
    const handleCloseCreateModal = () => setOpenCreateModal(false);
    
      
    const handleOpenUpdateModal = () => selectedRows.length ? setOpenUpdateModal(true) : setFeedback({open: true, message: "Select at least one record to update!", type: "info"})
    const handleCloseUpdateModal = () => setOpenUpdateModal(false);

    const columns = ResponsiveColumns()
    const addContact = (newContact) => setContacts(prevContacts => [...prevContacts, newContact]);

    // Define buttons for ActionButtons component
    const actionButtons = [
        { title: "Create New Contact", onClick: handleOpenCreateModal, icon: AddCircle, color: "primary" },
        { title: "Update Selected Record(s)", onClick: handleOpenUpdateModal, icon: Construction, color: "primary" },
    ];

    const handleCreateSubmit = async (data) => {
        console.log(data)
        const response = await API.Contacts.Create(cid, data, localStorage.token)

        if (response.status === 200) {
            addContact(response.data.data)
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }

        handleCloseCreateModal()
    }
    const handleUpdateSubmit = async (data) => {
        let temp = []
        
        let updatedFields = {}
        Object.keys(data).forEach(key => {
            if (data[key]) {
                Object.assign(updatedFields, { [key]: data[key] } )
            }
        });
        
        for (let i=0; i<selectedRows.length; i++) {
            temp.push({_id: selectedRows[i], data: updatedFields })
        }

        const response = await API.Contacts.Update(temp, localStorage.token)

        if (response.status === 200) {
            setContacts(prevContacts =>
                prevContacts.map(contact => {
                    const update = temp.find(update => update._id === contact._id);
                    if (!update) return contact; // No update for this contact
            
                    // Merge updated fields
                    const updatedContact = { ...contact, ...update.data };
            
                    // If firstName or lastName is updated, recalculate name
                    if (update.data.firstName || update.data.lastName) {
                        updatedContact.name = `${update.data.firstName ?? contact.firstName} ${update.data.lastName ?? contact.lastName}`;
                    }
            
                    return updatedContact;
                })
            );
            setFeedback(prevData => ({...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            console.log(response)
            setFeedback(prevData => ({ ...prevData, open: true, message: 'Error updating contact(s)...', type: 'error' }))
        }
        
        handleCloseUpdateModal(false) 
    }
    
    const handleDeleteSubmit = async () => {
        const response = await API.Contacts.Delete(cid, selectedRows, localStorage.token)
        if (response.status === 200) {
            setContacts(prevContacts =>
                prevContacts.filter(contact => !selectedRows.includes(contact._id))
            );
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: 'success' }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: 'error' }))
        }

        setSelectedRows([]) 
        handleCloseUpdateModal()
    }

    return (
        <FormProvider>
            <ActionButtons buttons={actionButtons} />
            <RecordModal open={openCreateModal} handleClose={handleCloseCreateModal} fields={ContactsConfig.createFields} title="Create New Contact" onSubmit={handleCreateSubmit} />
            <RecordModal open={openUpdateModal} handleClose={handleCloseUpdateModal} fields={ContactsConfig.updateFields} title="Update Contact(s)" onSubmit={handleUpdateSubmit} onDelete={handleDeleteSubmit} isUpdate={true} />
            <DataTable rows={contacts} columns={columns} handleSelectionChange={handleSelectionChange} />
        </FormProvider>
    )

}

export default Contacts