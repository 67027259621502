import { useEffect, useState } from "react"
import { FormProvider } from "../../contexts/CreateRecordFormContext"
import Feedback from "../../components/Feedback/Feedback"
import ActionButtons from "../../components/ActionButtons/ActionButtons"
import { Construction, FileDownload, ManageHistory } from "@mui/icons-material";
import RecordModal from "../../components/RecordModal/RecordModal";
import DataTable from "../../components/DataTable/DataTable";
import * as XLSX from 'xlsx';
import ResponsiveColumns from "./ResponsiveColumns";
import { API } from "../../lib/api";
import { useAuth } from "../../contexts/AuthContext"
import { Box, CircularProgress } from "@mui/material";

const Content = () => {
    const [opportunities, setOpportunities] = useState([])  
    const [selectedRows, setSelectedRows] = useState([]);
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""}) 
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [users, setUsers] = useState([])
    const [loading, setLoading] = useState(true);
    const { user } = useAuth()
    const columns = ResponsiveColumns()

    const handleSelectionChange = (selection) => setSelectedRows(selection)
      
    const handleOpenUpdateModal = () => selectedRows.length ? setOpenUpdateModal(true) : setFeedback({open: true, message: "Select at least one record to update!", type: "info"})
    const handleCloseUpdateModal = () => setOpenUpdateModal(false);


    const handleExport = () => {
        const today = new Date();
        const formattedDate = `${today.getFullYear()}-${(today.getMonth() + 1).toString().padStart(2, '0')}-${today.getDate().toString().padStart(2, '0')}`;
        const worksheet = XLSX.utils.json_to_sheet(opportunities);
        const workbook = XLSX.utils.book_new();
        XLSX.utils.book_append_sheet(workbook, worksheet, 'Data');
        XLSX.writeFile(workbook, `Opportunities_${formattedDate}.xlsx`);
    }

    const handleArchive = () => {

    }

    const handleUpdateSubmit = async (userData) => {
        let temp = []
        console.log(user.email)
        let updatedFields = {lastUpdatedBy: user.email, lastUpdatedDate: Date.now() }
        Object.keys(userData).forEach(key => {
            if (userData[key]) {
                if (key === 'owner') {
                    Object.assign(updatedFields, { [key]: userData[key]["value"]})
                } else {
                    Object.assign(updatedFields, { [key]: userData[key] } )
                }
            }
        });
        
        for (let i=0; i<selectedRows.length; i++) {
            temp.push({_id: selectedRows[i], data: updatedFields })
        }

        const response = await API.Opportunities.Update(temp)

        if (response.status === 200) {
            const updatedOpportunities = response.data.data
            setOpportunities(prevCustomers =>
                prevCustomers.map(customer => {
                    const update = updatedOpportunities.find(update => update._id === customer._id);
                    return update ? { ...customer, ...update } : customer;
                })
            );
            setFeedback(prevData => ({...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: 'Error updating opportunities...', type: "error" }))
        }
        
        handleCloseUpdateModal(false) 
    }

    const handleDeleteSubmit = async () => {
        const response = await API.Opportunities.Delete(selectedRows)
        if (response.status === 200) {
            setOpportunities(prevOpps =>
                prevOpps.filter(opportunity => !selectedRows.includes(opportunity._id))
            );
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            console.log(response)
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }

        setSelectedRows([]) 
        handleCloseUpdateModal()
    }

    const updateFields = [
        { name: 'owner', label: 'Owner', type: 'autocomplete', options: users },
        { name: 'salesStage', label: 'Stage', type: 'autocomplete', options: ['01 - Lead', '02 - Identified', '03 - Committee', '04 - Internal Revision', '05 - Committee Approval', '06 - Quote Sent', '07 - External Revision', '08 - Won'] },
        { name: 'closeDate', label: "Close Date", type: 'date' },
    ]

    // Define buttons for ActionButtons component
    const actionButtons = [
        { title: "Update Selected Record(s)", onClick: handleOpenUpdateModal, icon: Construction, color: "primary" },
        { title: "Opportunities Archive", onClick: handleArchive, icon: ManageHistory, color: "primary" },
        { title: "Export Data", onClick: handleExport, icon: FileDownload, color: "primary" }
    ];

    useEffect(() => {
        async function getData() {
            try {
                const response = await API.Opportunities.FetchAll()
                const usersResponse = await API.Users.FetchAll()   
                let tempUsers = usersResponse.map(user => {
                    return { value: user._id, label: `${user.firstName} ${user.lastName}`}
                })
                setUsers(tempUsers)
                setOpportunities(response)
            } catch (error) {
                setFeedback(prevData => ({ ...prevData, open: true, message: 'Error: Failed To Load Opportunities...', type: "error" }))
            } finally {
                setLoading(false)
            }
        }
        getData()
    },[])

    return (
        <>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <FormProvider>
                    <Feedback props={feedback} setFeedback={setFeedback} />
                    <ActionButtons buttons={actionButtons} />
                    <RecordModal open={openUpdateModal} handleClose={handleCloseUpdateModal} fields={updateFields} title="Update Opportunity" onSubmit={handleUpdateSubmit} onDelete={handleDeleteSubmit} isUpdate={true} />
                    <DataTable rows={opportunities} columns={columns} handleSelectionChange={handleSelectionChange} />
                </FormProvider> 
            )}   
        </>    
    )
}

export default Content