// ActionButtons.js
import React from 'react';
import { IconButton, Tooltip } from "@mui/material";

const ActionButtons = ({ buttons }) => (
    <div className="row">
        <div className="col">
            {buttons.map(({ title, onClick, icon: Icon, color }, idx) => (
                <Tooltip key={idx} title={title}>
                    <IconButton onClick={onClick}>
                        <Icon color={color || "primary"} />
                    </IconButton>
                </Tooltip>
            ))}
        </div>
    </div>
);

export default ActionButtons;
