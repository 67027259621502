// AuthContext.js
// import { jwtDecode } from "jwt-decode";
import axios from 'axios';
import { createContext, useContext, useState, useEffect } from 'react';

const AuthContext = createContext();

export const AuthProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  // Check if user is logged in via cookie
  useEffect(() => {
    localStorage.removeItem('token');
    const checkAuth = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/auth/check`, {
          withCredentials: true
        });
        setUser(response.data.user); // comes from req.user in backend
      } catch (err) {
        setUser(null); // not logged in
      } finally {
        setLoading(false);
      }
    };

    checkAuth();
  }, []);

  const login = (userData) => {
    setUser(userData);
    localStorage.setItem('user', JSON.stringify(userData)); // this is fine
    localStorage.setItem('id', userData.id); // optional depending on how you use it
  };

  const logout = async () => {
    await axios.post(`${process.env.REACT_APP_SERVER_BASE}/auth/logout`, {}, {
      withCredentials: true
    });
    setUser(null);
  };

  return (
    <AuthContext.Provider value={{ user, login, logout, loading }}>
      {children}
    </AuthContext.Provider>
  );
};

export const useAuth = () => useContext(AuthContext);


// const AuthContext = createContext();

// export const AuthProvider = ({ children }) => {
//   const [user, setUser] = useState(null);
//   const [loading, setLoading] = useState(true);

//   useEffect(() => {
//     const storedUser = localStorage.getItem('user');

//     if (storedUser) {
//       const userData = JSON.parse(storedUser);
//       const { token } = userData;

//       try {
//         const decodedToken = jwtDecode(token);
//         // Check if the token is expired
//         const isExpired = decodedToken.exp < Date.now() / 1000;

//         if (!isExpired) {
//           setUser(userData);
//         }
//       } catch (error) {
//         console.error("Error decoding token:", error);
//       }
//     }

//     setLoading(false);
//   }, []);

//   const login = (userData) => {
//     setUser(userData);
//     localStorage.setItem('user', JSON.stringify(userData)); // this is fine
//     localStorage.setItem('id', userData.id); // optional depending on how you use it
//   };

//   const logout = () => {
//     setUser(null);
//     localStorage.removeItem('user');
//     localStorage.removeItem('token');
//     localStorage.removeItem('id');
//   };

//   return (
//     <AuthContext.Provider value={{ user, login, logout, loading }}>
//       {children}
//     </AuthContext.Provider>
//   );
// };

// export const useAuth = () => {
//   return useContext(AuthContext);
// };
