import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';
const MyQuotesChart = ({ quotes }) => {
    const [sortedQuotes, setSortedQuotes] = useState([])

    const option = {
        tooltip: {
            trigger: 'item'
        },
        legend: {
            top: '5%',
            left: 'center'
        },
        series: [
            {
                name: 'Quotes By Status',
                type: 'pie',
                radius: ['40%', '70%'],
                avoidLabelOverlap: false,
                label: {
                    show: false,
                    position: 'center'
                },
                emphasis: {
                    label: {
                        show: true,
                        fontSize: 16,
                        fontWeight: 'bold'
                    }
                },
                labelLine: {
                    show: false
                },
                data: sortedQuotes
            }
        ]
    }

    useEffect(() => {
        if (quotes.length > 0) {
            // Step 1: Initialize an empty object to keep track of counts
            const statusCounts = {};
    
            // Step 2: Iterate through the array and count occurrences of each status
            quotes.forEach(quote => {
                if (statusCounts[quote.status]) {
                    statusCounts[quote.status]++;
                } else {
                    statusCounts[quote.status] = 1;
                }
            });
    
            // Step 3: Convert the counts object into the desired array format
            const result = Object.keys(statusCounts).map(status => ({
                value: statusCounts[status],
                name: status,
            }));
    
            setSortedQuotes(result)
        }
      }, [quotes]);

    return (
        <div className="col-lg-4">
            <div className="card">
                <div className="card-body">
                    <div className="d-flex align-items-center justify-content-between">
                        <h5 className="mb-0">Opportunities By Status</h5>
                    </div>
                    <ReactECharts
                        option={option}
                        style={{ height: '300px' }}
                        opts={{renderer: 'svg'}} // use svg to render the chart.
                    />
                </div>
            </div>
        </div>
    )
}

export default MyQuotesChart