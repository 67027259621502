import React, { useEffect, useState } from "react"
import Feedback from "../../components/Feedback/Feedback"
import Calendar from "./Calendar"
import { formatEvents } from "../../lib/helpers"
import { API } from "../../lib/api";
import { Box, CircularProgress } from "@mui/material";

const Content = () => {
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""})
    const [events, setEvents] = useState([])
    const [loading,setLoading] = useState(true)
    useEffect(() => {
        async function getData() {
            try {
                // -- Format Opportunities for FullCalendar Component -- //
                const response = await API.Opportunities.FetchAll()
                const formattedEvents = formatEvents(response)

                setEvents(formattedEvents);   
            } catch (error) {
                setFeedback(prevData => ({ ...prevData, open: true, message: 'Error: Failed To Load Opportunities...', type: "error" }))
            } finally {
                setLoading(false)
            }
        }

        getData()
    }, [])

    return (
        <React.Fragment>
            {loading ? (
                <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px">
                    <CircularProgress />
                </Box>
            ) : (
                <React.Fragment>
                    <Feedback props={feedback} setFeedback={setFeedback} />
                    <Calendar events={events} setFeedback={setFeedback} />
                </React.Fragment>
            )}
        </React.Fragment>
    )
}

export default Content