import ReactECharts from 'echarts-for-react';
const DoughNut = () => {
    const option = {
      tooltip: {
          trigger: 'item'
        },
        legend: {
          top: '5%',
          left: 'center'
        },
        series: [
          {
            name: 'Tasks By',
            type: 'pie',
            radius: ['40%', '70%'],
            avoidLabelOverlap: false,
            label: {
              show: false,
              position: 'center'
            },
            emphasis: {
              label: {
                show: true,
                fontSize: 16,
                fontWeight: 'bold'
              }
            },
            labelLine: {
              show: false
            },
            data: [
              { value: 1048, name: 'Search Engine' },
              { value: 735, name: 'Direct' },
              { value: 580, name: 'Email' },
              { value: 484, name: 'Union Ads' },
              { value: 300, name: 'Video Ads' }
            ]
          }
        ]
  }
    return (
        <div className="col-lg-6">
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Report B</h5>
                </div>
                <ReactECharts
                    option={option}
                    style={{ height: '300px' }}
                    opts={{renderer: 'svg'}} // use svg to render the chart.
                />
            </div>
        </div>
    </div>
    )
}

export default DoughNut