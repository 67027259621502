import { useAuth } from "../../../contexts/AuthContext"
import MenuIcon from '@mui/icons-material/Menu';
import SettingsIcon from '@mui/icons-material/Settings';

const Header = () => {
    const { logout } = useAuth(); // Import the login function from your AuthContex
    const handleLogout = () => {
        logout()
    }
    const handleSideBar = () => {
        let side = document.querySelector(".pc-sidebar")
        if (side.classList.contains('pc-sidebar-hide')) {
            side.classList.remove('pc-sidebar-hide')
            
        } else {
            side.classList.add('pc-sidebar-hide')
        }
        if (side.classList.contains("mob-sidebar-active")){
            side.classList.remove("mob-sidebar-active")
        } else {
            side.classList.add("mob-sidebar-active")
        }
    }

    return (
        <header className="pc-header">
            <div className="header-wrapper"> {/* [Mobile Media Block] start */}
            <div className="me-auto pc-mob-drp">
            <ul className="list-unstyled">
                {/* ======= Menu collapse Icon ===== */}
                <li className="pc-h-item pc-sidebar-collapse">
                    <a href="#" className="pc-head-link ms-0" id="sidebar-hide" onClick={handleSideBar}>
                        <MenuIcon/>
                    </a>
                </li>
                <li className="pc-h-item pc-sidebar-popup">
                    <a href="#" className="pc-head-link ms-0" id="mobile-collapse" onClick={handleSideBar}>
                        <MenuIcon/>
                    </a>
                </li>
                <li style={{display: "flex", alignItems: "center"}}>
                    <a href="/settings" className="pc-head-link ms-0">
                        <SettingsIcon/>
                    </a>
                </li>
            </ul>
            </div>
            {/* [Mobile Media Block end] */}
            </div>
        </header>
    )
}

export default Header