import { useState } from "react"
import AuthForm from "./AuthForm"
import Feedback from "../../components/Feedback/Feedback"

const Login = () => {
    const [feedback, setFeedback] = useState({open: false, message: "", type: ''}) 

    return (
        <>
            <Feedback props={feedback} setFeedback={setFeedback} />
            <AuthForm setMessage={setFeedback} />
        </>
    )
}

export default Login