const OpportunitiesConfig = {
 
    createUserFields: [
      { name: 'customer', label: "Customer", type: 'autocomplete', options: ['1', '2'] },
      { name: 'dueDate', label: "Due Date", type: 'date' },
      { name: 'priority', label: 'Priority', type: 'autocomplete', options: ['Low', 'Medium', 'High'] }
    ],

    updateUserFields: [
      { name: 'assignedTo', label: "Assigned To", type: 'autocomplete', options: ['1','2','3'] },
      { name: 'priority', label: 'Priority', type: 'autocomplete', options: ['Low', 'Medium', 'High']},
      { name: 'status', label: 'Status', type: 'autocomplete', options: ['Not Started', 'In Progress', 'Pending Approval', 'Complete', 'Overdue'] },
    ],
    
    updateLineFields: [
      { name: "productGroup", label: "Product Group", type: "autocomplete", options: ['ABS Root Product Group', 'Buyout', 'Components', 'Equipment', 'Installation', 'LIAD', 'Moretto', 'Service', 'Silos', 'Spare Parts', 'TM - Components', 'TM - Equipment', 'TM - Installation', 'TM - Spare Parts', 'TM - Warranty Parts', 'TM Products Group', 'Warranty Parts']},
      { name: "lineComments", label: "Line Description", type: 'text'},
      { name: "amount", label: "Amount", type: "number"},
      { name: "bookedOrdersStage", label: "Booked Orders Stage", type: "autocomplete", options: ['01 - Assign Resources', '02 - Submittals Sent', '03 - Submittals Approved', '04 - Shipped', '05 - Installed', '06 - Commissioned', '07 - Revenue Taken', '10 - Closed']},
      { name: "shipDate", label: "Ship Date", type: "date" }
    ],

    createLineFields: [
      { name: "productGroup", label: "Product Group", type: "autocomplete", options: ['ABS Root Product Group', 'Buyout', 'Components', 'Equipment', 'Installation', 'LIAD', 'Moretto', 'Service', 'Silos', 'Spare Parts', 'TM - Components', 'TM - Equipment', 'TM - Installation', 'TM - Spare Parts', 'TM - Warranty Parts', 'TM Products Group', 'Warranty Parts']},
      { name: "lineComments", label: "Line Description", type: 'text'},
      { name: "amount", label: "Amount", type: "number"},
      { name: "bookedOrdersStage", label: "Booked Orders Stage", type: "autocomplete", options: ['01 - Assign Resources', '02 - Submittals Sent', '03 - Submittals Approved', '04 - Shipped', '05 - Installed', '06 - Commissioned', '07 - Revenue Taken', '10 - Closed']},
      { name: "shipDate", label: "Ship Date", type: "date", required: true }
    ],

    fakeOppData: [
        { _id: 1, qid: 30, name: "ABS Custom Name 1", assignedTo: { firstName: "Evan", lastName: "Mercer" }, dueDate: "2024-10-15T00:00:00", endDate: "", customer: { name: "Global Dynamics" }, priority: "Medium", status: "In Progress" },
        { _id: 2, qid: 31, name: "ABS Custom Name 2", assignedTo: { firstName: "Sophie", lastName: "Caldwell" }, dueDate: "2024-10-22T00:00:00", endDate: "", customer: { name: "Tech Innovators" }, priority: "High", status: "Pending Approval" },
        { _id: 3, qid: 32, name: "ABS Custom Name 3", assignedTo: { firstName: "Liam", lastName: "Ashford" }, dueDate: "2024-11-04T00:00:00", endDate: "", customer: { name: "Bright Horizons" }, priority: "Low", status: "Completed" },
        { _id: 4, qid: 33, name: "ABS Custom Name 4", assignedTo: { firstName: "Grace", lastName: "Winslow" }, dueDate: "2024-11-10T00:00:00", endDate: "", customer: { name: "BlueWave Solutions" }, priority: "Medium", status: "In Progress" },
        { _id: 5, qid: 34, name: "ABS Custom Name 5", assignedTo: { firstName: "Owen", lastName: "Harrington" }, dueDate: "2024-11-18T00:00:00", endDate: "", customer: { name: "Quantum Tech" }, priority: "High", status: "Pending Approval" },
        { _id: 6, qid: 35, name: "ABS Custom Name 6", assignedTo: { firstName: "Ella", lastName: "Radcliffe" }, dueDate: "2024-11-24T00:00:00", endDate: "", customer: { name: "NextGen Systems" }, priority: "Low", status: "In Progress" },
        { _id: 7, qid: 36, name: "ABS Custom Name 7", assignedTo: { firstName: "Miles", lastName: "Thornton" }, dueDate: "2024-11-30T00:00:00", endDate: "", customer: { name: "FutureWorks" }, priority: "Medium", status: "Pending Approval" },
        { _id: 8, qid: 37, name: "ABS Custom Name 8", assignedTo: { firstName: "Chloe", lastName: "Beckett" }, dueDate: "2024-12-05T00:00:00", endDate: "", customer: { name: "Pioneer Enterprises" }, priority: "High", status: "In Progress" },
        { _id: 9, qid: 38, name: "ABS Custom Name 9", assignedTo: { firstName: "Logan", lastName: "Whitmore" }, dueDate: "2024-12-10T00:00:00", endDate: "", customer: { name: "NovaTech" }, priority: "Low", status: "Completed" },
        { _id: 10, qid: 39, name: "ABS Custom Name 10", assignedTo: { firstName: "Evan", lastName: "Mercer" }, dueDate: "2024-12-12T00:00:00", endDate: "", customer: { name: "Vanguard Industries" }, priority: "Medium", status: "Pending Approval" },
        { _id: 11, qid: 40, name: "ABS Custom Name 11", assignedTo: { firstName: "Sophie", lastName: "Caldwell" }, dueDate: "2024-12-16T00:00:00", endDate: "", customer: { name: "Infinity Labs" }, priority: "High", status: "In Progress" },
        { _id: 12, qid: 41, name: "ABS Custom Name 12", assignedTo: { firstName: "Liam", lastName: "Ashford" }, dueDate: "2024-12-20T00:00:00", endDate: "", customer: { name: "Global Dynamics" }, priority: "Low", status: "Completed" },
        { _id: 13, qid: 42, name: "ABS Custom Name 13", assignedTo: { firstName: "Grace", lastName: "Winslow" }, dueDate: "2024-12-23T00:00:00", endDate: "", customer: { name: "Tech Innovators" }, priority: "Medium", status: "Pending Approval" },
        { _id: 14, qid: 43, name: "ABS Custom Name 14", assignedTo: { firstName: "Owen", lastName: "Harrington" }, dueDate: "2024-12-29T00:00:00", endDate: "", customer: { name: "Bright Horizons" }, priority: "High", status: "In Progress" }
      ]
}

export default OpportunitiesConfig