const NavItem = ({ iconId, text, link, submenuItems, icon }) => {
    return (
        <li className={`pc-item ${submenuItems ? 'pc-hasmenu' : ''}`}>
            <a href={link || '#!'} className="pc-link">
                <span className='side-nav-icon'>
                    {icon}
                </span>
                <span className="pc-mtext">{text}</span>
                {submenuItems && <span className="pc-arrow"><i data-feather="chevron-right"></i></span>}
            </a>
            {submenuItems && (
                <ul className="pc-submenu">
                    {submenuItems.map((submenuItem, index) => (
                        <li key={index} className="pc-item">
                            <a className="pc-link" href={submenuItem.link}>
                                {submenuItem.text}
                            </a>
                        </li>
                    ))}
                </ul>
            )}
        </li>
    )
};

export default NavItem