import { Button, FormControl, IconButton, InputAdornment, InputLabel, MenuItem, Select, Switch, TextField, Tooltip } from "@mui/material"
import { useEffect, useState } from "react"
import { API } from "../../lib/api"
import Feedback from "../../components/Feedback/Feedback"
import { Visibility, VisibilityOff } from "@mui/icons-material"
import { useAuth } from "../../contexts/AuthContext";

const Content = () => {
    const [role, setRole] = useState()
    const [firstName, setFirstName] = useState()
    const [lastName, setLastName] = useState()
    const [email, setEmail] = useState()
    const [password, setPassword] = useState()
    const [newPassword, setNewPassword] = useState()
    const [feedback, setFeedback] = useState({open: false, message: "", type: ""})  
    const [showApiKey, setShowApiKey] = useState(false);
    const [userSettings, setUserSettings] = useState({})
    const { user } = useAuth()

    const handleRoleChange = (e) => {
        setRole(e.target.value)
    }

    const handleToggleApiKey = () => {
      setShowApiKey((prev) => !prev);
    };

    const handleUpdate = async () => {
        const tempData = { 
            ...(firstName && { firstName }),
            ...(lastName && { lastName }),
            ...(email && { email }),
            ...(password && {password }),
            ...(newPassword && {newPassword }),
            ...(role && {role})
        }

        const response = await API.Users.UpdateOne(user.userId, tempData)
        if (response.status === 200) {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }
    }

    const handleSettingsChange = async (name, bool) => {
        // Optimistically update UI
        setUserSettings(prev => ({
            ...prev,
            [name]: bool
        }));
    
        const response = await API.Settings.Update(user.userId, { [name]: bool });
    
        if (response.status === 200) {
            setFeedback(prevData => ({
                ...prevData,
                open: true,
                message: response.data.message,
                type: "success"
            }));
        } else {
            // Optionally revert the state on error
            setUserSettings(prev => ({
                ...prev,
                [name]: !bool
            }));
    
            setFeedback(prevData => ({
                ...prevData,
                open: true,
                message: response.data.message || "Failed to update setting",
                type: "error"
            }));
        }
    };

    useEffect(() => {
        if (!user) return; // 🔐 wait for user to be available
        async function fetchSettings() {
            const response = await API.Settings.FetchByUser(user.userId)
            setUserSettings(response)
        }
        fetchSettings()
    },[user])

    return (
        <>
        <Feedback props={feedback} setFeedback={setFeedback} />
        <div className="row" style={{minHeight: "100vh"}}>
            {/* User/Profile Settings */}
            <div className="col-xl-6 col-md-12">
                <div className='card'>
                    <div className="card-header">
                        <h5>Profile Settings</h5>
                    </div>
                    <div className="card-body">
                        <div style={{display: 'flex', justifyContent: "center", flexWrap: "wrap"}}>
                            <div className='col-11 col-lg-5' style={{margin: "1em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="First Name"
                                        variant="outlined"
                                        onChange={(e) => setFirstName(e.target.value)}
                                        value={firstName}
                                    />
                                </FormControl>
                            </div>
                            <div className='col-11 col-lg-5' style={{margin: "1em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Last Name"
                                        variant="outlined"
                                        onChange={(e) => setLastName(e.target.value)}
                                        value={lastName}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{display: 'flex', justifyContent: "center", flexWrap: "wrap"}}>
                            <div className='col-11 col-lg-5' style={{margin: "1em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        label="Email"
                                        variant="outlined"
                                        onChange={(e) => setEmail(e.target.value)}
                                        value={email}
                                    />
                                </FormControl>
                            </div>
                            <div className='col-11 col-lg-5' style={{margin: "1em"}}>
                                <FormControl fullWidth>
                                <InputLabel id="role-label">Role</InputLabel>
                                    <Select
                                        labelId="role-label"
                                        id="role-select"
                                        value={role} // would be the curr user.role
                                        label="Role"
                                        onChange={handleRoleChange}
                                    >
                                        <MenuItem value={"admin"}>Admin</MenuItem>
                                        <MenuItem value={"manager"}>Manager</MenuItem>
                                        <MenuItem value={"user"}>User</MenuItem>
                                    </Select>
                                </FormControl>
                            </div>
                        </div>
                        <h6 style={{marginTop: "2em", marginBottom: "2em"}}>Change Password</h6>
                        <div style={{display: "flex", justifyContent: "start", flexWrap: "wrap"}}>
                            <div className="col-11 col-sm-10 col-md-10 col-lg-6" style={{margin: "1em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="password"
                                        label="Current Password"
                                        variant="outlined"
                                        onChange={(e) => setPassword(e.target.value)}
                                        value={password}
                                    />
                                </FormControl>
                            </div>
                            <div className="col-11 col-sm-10 col-md-10 col-lg-6" style={{margin: "1em"}}>
                                <FormControl fullWidth>
                                    <TextField
                                        type="password"
                                        label="New Password"
                                        variant="outlined"
                                        onChange={(e) => setNewPassword(e.target.value)}
                                        value={newPassword}
                                    />
                                </FormControl>
                            </div>
                        </div>
                        <div style={{display: "flex", justifyContent: "center"}}>
                            <div className="col-lg-4" style={{margin: "1em"}}>
                                <Button variant="outlined" onClick={handleUpdate}>Save Changes</Button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            
            {/* Email Notifications Settings */}
            <div className="col-xl-5 col-md-12">
                <div className='card'>
                    <div className="card-header">
                        <h5>Email Settings</h5>
                    </div>
                    <div className="card-body">
                        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Opportunity Assigned</p>
                            <Tooltip title="Receive an email when an opportunity gets reassigned to you - Currently Unavailable">
                            <Switch
                                onChange={(e) => handleSettingsChange("emailOpportunityAssigned", e.target.checked) }
                                checked={userSettings?.emailOpportunityAssigned}
                            />
                            </Tooltip>
                        </div>
                        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Opportunity Status Changes</p>
                            <Tooltip title="Receive an email when an opportunity assigned to you has a change in status">
                            <Switch
                                onChange={(e) => handleSettingsChange("emailOpportunityStatusChanges", e.target.checked) }
                                checked={userSettings?.emailOpportunityStatusChanges}
                            />
                            </Tooltip>
                        </div>
                        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Opportunity Close Date Changes</p>
                            <Tooltip title="Receive an email when an opportunity assigned to you has a change in ship date - Currently Unavailable">
                            <Switch
                                onChange={(e) => handleSettingsChange("emailCloseDateChanges", e.target.checked) }
                                checked={userSettings?.emailCloseDateChanges}
                            />
                            </Tooltip>
                        </div>
                        {/* <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Project Completion</p>
                            <Tooltip title="Receive an email when a project/opportunity assigned to you is marked as complete - Currently Unavailable">
                            <Switch
                                checked={userSettings?.emailOpportunityStatusChanges}
                            />
                            </Tooltip>
                        </div> */}
                        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Opportunity Reminder</p>
                            <Tooltip title="Receive an email when an opportunity assigned to you has not had any changes for 2 weeks - Currently Unavailable">
                            <Switch
                                onChange={(e) => handleSettingsChange("emailOpportunityReminder", e.target.checked) }
                                checked={userSettings?.emailOpportunityReminder}
                            />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div> 

            {/* Global Settings */}
            <div className="col-xl-10 col-md-12">
                <div className='card'>
                    <div className="card-header">
                        <h5>Global Settings</h5>
                    </div>
                    <div className="card-body">
                        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Speed Dial:</p>
                            <Tooltip title="Currently Unavailable">
                            <Switch
                            />
                            </Tooltip>
                        </div>
                        <h6 style={{marginTop: "2em", marginBottom: "2em"}}>Themes</h6>
                        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Default</p>
                            <Tooltip title="Currently Unavailable">
                                <Switch
                                />
                            </Tooltip>
                        </div>
                        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Honey Comb</p>
                            <Tooltip title="Currently Unavailable">
                                <Switch
                                />
                            </Tooltip>
                        </div>
                        <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                            <p style={{color: "#6c757d", fontSize: "14px"}}>Dark</p>
                            <Tooltip title="Currently Unavailable">
                                <Switch
                                />
                            </Tooltip>
                        </div>
                    </div>
                </div>
            </div>


            <div className="col-xl-10 col-md-12">
                <div className='card'>
                    <div className="card-header">
                        <h5>API Settings</h5>
                    </div>
                    <div className="card-body">
                    <div style={{display:"flex", justifyContent: "space-between", alignItems: "center", marginBottom: "0.25em"}}>
                        <div className="col-2">
                        <p style={{color: "#6c757d", fontSize: "14px"}}>API Key:</p>
                        </div>
                        <FormControl fullWidth>
                            <TextField
                            variant="outlined"
                            fullWidth
                            value={user?.apiKey ? user.apiKey : ""}
                            type={showApiKey ? "text" : "password"} // Toggles between hidden and visible
                            InputProps={{
                                readOnly: true, // Prevents typing
                                endAdornment: (
                                <InputAdornment position="end">
                                    <IconButton onClick={handleToggleApiKey} edge="end">
                                    {showApiKey ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                                ),
                            }}
                            />
                        </FormControl>
                    </div>
                </div>
                </div>
            </div>
        </div>
        </>
    )
}

export default Content