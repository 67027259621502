import { createContext, useContext, useState } from 'react';

const CreateRecordFormContext = createContext();

export const FormProvider = ({ children }) => {
  const [formState, setFormState] = useState({});

  const updateField = (fieldName, value) => {
    setFormState((prev) => ({
      ...prev,
      [fieldName]: value,
    }));
  };

  const resetForm = () => {
    setFormState({});
  };

  return (
    <CreateRecordFormContext.Provider value={{ formState, updateField, resetForm  }}>
      {children}
    </CreateRecordFormContext.Provider>
  );
};

export const useFormContext = () => useContext(CreateRecordFormContext);
