import { useMediaQuery } from '@mui/material';

const ResponsiveColumns = () => {
    // Define the breakpoints
    const isMobile = useMediaQuery('(max-width:600px)');

    return [
        {
            field: "name",
            headerName: "Name",
            flex: 1,
            cellClassName: "name-column--cell",
          },
            // Conditionally show/hide columns
            ...(isMobile ? [] : [
                {
                    field: 'contactJobTitle',
                    headerName: "Job Title",
                    flex: 1
                },
                {
                    field: 'phoneNumber',
                    headerName: "Primary Phone",
                    flex: 1
                },
            ]),
            {
                field: "primaryEmail",
                headerName: "Email",
                flex: 1,
                renderCell: ({ row: { primaryEmail } }) => <a href={`mailto:${primaryEmail}`}>{primaryEmail}</a>
            }
    ];
};

export default ResponsiveColumns