import ReactECharts from 'echarts-for-react';
import { useEffect, useState } from 'react';

const Bar = () => {
    const option = {
        xAxis: {
            type: 'category',
            data: ['Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat', 'Sun']
          },
          yAxis: {
            type: 'value'
          },
          series: [
            {
              data: [120, 200, 150, 80, 70, 110, 130],
              type: 'bar'
            }
          ]
    }

    return (
        <div className="col-lg-6">
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Report A</h5>
                </div>
                <ReactECharts
                    option={option}
                    style={{ height: '300px' }}
                    opts={{renderer: 'svg'}} // use svg to render the chart.
                />
            </div>
        </div>
    </div>
    )
}

export default Bar