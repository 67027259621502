import { Alert, IconButton, Snackbar } from "@mui/material"
import React from "react"
import CloseIcon from '@mui/icons-material/Close';

const Feedback = ({props, setFeedback}) => {
  const handleClose = () => {
      setFeedback({open: false, message: "", type: ""})
  }
  const action = (
      <React.Fragment>
        <IconButton
          size="small"
          aria-label="close"
          color="inherit"
          onClick={handleClose}
        >
          <CloseIcon fontSize="small" />
        </IconButton>
      </React.Fragment>
  )


  return (
      
      <Snackbar
          open={props?.open}
          autoHideDuration={5000}
          onClose={handleClose}
          action={action}
      >
        <Alert
          onClose={handleClose}
          severity={props?.type}
          variant="filled"
          sx={{ width: '100%' }}
        >
        {props?.message}
        </Alert>
      </Snackbar>
  )
}

export default Feedback