import BaseWrapper from "../../components/BaseWrapper/BaseWrapper"

const Construction = () => {
    return (
        // <BaseWrapper
            // content={
                <div style={{display: "flex", flexDirection: "column", alignItems: "center", minHeight: "100vh", justifyContent: "center"}}>
                    <div>
                        <img src="assets/images/pages/img-cunstruct-2.svg" alt="construction" />
                    </div>
                    <div>
                        <h2>Under Construction</h2>
                    </div>
                    <div>
                        <p className="text-muted">The Hive is currently under maintenance right now.</p>
                        <p className="text-muted">Scheduled Maintenance: 3/22/24 8:00AM - 3/24/24 1:00PM</p>
                    </div>
                </div>
            // }
        // />
    )
}

export default Construction