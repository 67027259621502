import { Card, CardContent, CardHeader } from "@mui/material"
import ActionButtons from "../ActionButtons/ActionButtons"
import { AddCircle, Construction } from "@mui/icons-material";
import { useEffect, useState } from "react";
import RecordModal from "../RecordModal/RecordModal";
import OpportunitiesConfig from "../../lib/config/opportunities";
import { FormProvider } from "../../contexts/CreateRecordFormContext";
import ResponsiveColumns from "./ResponsiveColumns";
import { DataGridPro } from '@mui/x-data-grid-pro';
import { API } from "../../lib/api";
import { useAuth } from "../../contexts/AuthContext";
// import { jwtDecode } from "jwt-decode";

const Lines = ({ setFeedback, project, setLogs }) => {
    const [selectedRows, setSelectedRows] = useState([]);
    const [openCreateModal, setOpenCreateModal] = useState(false);
    const [openUpdateModal, setOpenUpdateModal] = useState(false);
    const [rows, setRows] = useState([]);
    const columns = ResponsiveColumns()
    // let user = jwtDecode(localStorage?.token);
    const { user } = useAuth()
    const addLine = (newLine) => setRows(prevLines => [...prevLines, newLine]);

    const handleSelectionChange = (selection) => setSelectedRows(selection)

    const handleOpenCreateModal = () => setOpenCreateModal(true);
    const handleCloseCreateModal = () => setOpenCreateModal(false);
      
    const handleOpenUpdateModal = () => selectedRows.length ? setOpenUpdateModal(true) : setFeedback({open: true, message: "Select at least one record to update!", type: "info"})
    const handleCloseUpdateModal = () => setOpenUpdateModal(false);
    
    // API Request to Create a new Line
    const handleCreateSubmit = async (data) => {
        Object.assign(data, { createdBy: user.email })

        // Check to see if required fields have been filled out
        if (!data?.shipDate) {
            setFeedback(prevData => ({ ...prevData, open: true, message: "Error: Ship Date Required... ", type: "error" }))
            return
        }
        if (data.productGroup === undefined) {
            setFeedback(prevData => ({ ...prevData, open: true, message: "Error: Product Group Required... ", type: "error" }))
            return
        }

        const response = await API.Lines.Create(project._id, data)

        if (response.status === 200) {
            console.log(response.data.data)
            addLine(response.data.data)
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
            const fetchNewLogs = await API.OpportunityLogs.FetchByOppId(project._id)
            setLogs(fetchNewLogs)
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "error" }))
        }

        handleCloseCreateModal()
    };

    // revenueLineTypeCode: Path `revenueLineTypeCode` is required., creationDate: Path `creationDate` is required., createdBy: Path `createdBy` is required., revenueOpportunityId: Path `revenueOpportunityId` is required

    // API Request to Delete a Line
    const handleDeleteSubmit = async () => {
        const response = await API.Lines.Delete(selectedRows)
        if (response.status === 200) {
            setRows(prevLines =>
                prevLines.filter(line => !selectedRows.includes(line._id))
            );
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            setFeedback(prevData => ({ ...prevData, open: true, message: response.data.message, type: 'error' }))
        }

        setSelectedRows([]) 
        handleCloseUpdateModal()
    }

    const handleUpdateSubmit = async (updateData) => {
        let temp = []
        
        let updatedFields = {}
        Object.keys(updateData).forEach(key => {
            if (updateData[key]) {
                Object.assign(updatedFields, { [key]: updateData[key] } )
            }
        });
        
        for (let i=0; i<selectedRows.length; i++) {
            temp.push({_id: selectedRows[i], data: updatedFields })
        }
        
        const response = await API.Lines.UpdateMulti(project._id, temp)

        if (response.status === 200) {
            setRows(prevLines =>
                prevLines.map(line => {
                    const update = temp.find(update => update._id === line._id);
                    return update ? { ...line, ...update.data } : line;
                })
            );
            setFeedback(prevData => ({...prevData, open: true, message: response.data.message, type: "success" }))
        } else {
            console.log(response)
            setFeedback(prevData => ({ ...prevData, open: true, message: 'Error updating line(s)...', type: "error" }))
        }
        
        handleCloseUpdateModal(false)         
    }

    const actionButtons = [
        { title: "Create Revenue Line", onClick: handleOpenCreateModal, icon: AddCircle, color: "primary" },
        { title: "Update Selected Record(s)", onClick: handleOpenUpdateModal, icon: Construction, color: "primary" },
    ];

    const handleProcessRowUpdate = (newRow) => {
        const updatedRows = rows.map((row) =>
          row._id === newRow._id ? { ...row, ...newRow } : row
        );
        setRows(updatedRows);
        return newRow; // Return the updated row to update the UI
    };

    useEffect(() => {
        project && console.log(project)
        project && setRows(project?.lines)
        // async function getData() {
        //     const response = await API.Lines.FetchByOpportunity(oid, localStorage.token)
        //     setRows(response)
        // }
        // getData()
    },[project])

    return (
        <FormProvider>
            <RecordModal open={openCreateModal} handleClose={handleCloseCreateModal} fields={OpportunitiesConfig.createLineFields} title={"Create Revenue Line"} onSubmit={handleCreateSubmit} />
            <RecordModal open={openUpdateModal} handleClose={handleCloseUpdateModal} fields={OpportunitiesConfig.updateLineFields} title={"Update Revenue Line(s)"} onSubmit={handleUpdateSubmit} onDelete={handleDeleteSubmit} isUpdate={true} />
            <div className="col-xl-12 col-md-12">
                <Card>
                    <CardHeader title="Revenue Lines" action={<ActionButtons buttons={actionButtons} />} />
                    <CardContent>
                        <DataGridPro
                            checkboxSelection
                            rows={rows}
                            columns={columns}
                            getRowId={(row) => row._id}
                            processRowUpdate={handleProcessRowUpdate} // Handles updates
                            experimentalFeatures={{ newEditingApi: true }} // Enable new editing API
                            onRowSelectionModelChange={handleSelectionChange}
                        />
                    </CardContent>
                </Card>
            </div>
        </FormProvider>
    )
}

export default Lines