const ContactsConfig = {
    createFields: [
        { name: 'firstName', label: "First Name", type: 'text', required: true },
        { name: "lastName", label: "Last Name", type: 'text', required: true },
        { name: "primaryEmail", label: "Email", required: true },
        { name: "phoneNumber", label: "Phone" },
        { name: "contactJobTitle", label: "Job Title" },
    ],

    // -- Users | Update User Modal | Form Fields -- //
    updateFields: [
        { name: 'firstName', label: "First Name", type: 'text' },
        { name: "lastName", label: "Last Name", type: 'text' },
        { name: "primaryEmail", label: "Email" },
        { name: "phoneNumber", label: "Phone" },
        { name: "contactJobTitle", label: "Job Title" },
    ],
}


export default ContactsConfig