import ReactECharts from 'echarts-for-react';
import { overlapdata } from "../../lib/chartsData/overlapdata"
 
const Overlap = () => {
    const option = {
        tooltip: {},
        legend: [
          {
            data: overlapdata.categories.map(function (a) {
              return a.name;
            })
          }
        ],
        series: [
          {
            name: 'Les Miserables',
            type: 'graph',
            layout: 'none',
            data: overlapdata.nodes,
            links: overlapdata.links,
            categories: overlapdata.categories,
            roam: true,
            label: {
              show: true,
              position: 'right',
              formatter: '{b}'
            },
            labelLayout: {
              hideOverlap: true
            },
            scaleLimit: {
              min: 0.4,
              max: 2
            },
            lineStyle: {
              color: 'source',
              curveness: 0.3
            }
          }
        ]
    };

    return (
        <div className="col-lg-12">
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Report G</h5>
                </div>
                <ReactECharts
                    option={option}
                    style={{ height: '700px' }}
                    opts={{renderer: 'svg'}} // use svg to render the chart.
                />
            </div>
        </div>
    </div>
    )
    
}

export default Overlap