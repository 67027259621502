import FullCalendar from '@fullcalendar/react'
import dayGridPlugin from '@fullcalendar/daygrid'
import interactionPlugin from '@fullcalendar/interaction'
import React from 'react'

const Calendar = ({ events, setFeedback }) => {

    const renderEventContent = (eventInfo) => <i>{eventInfo.event.title}</i>

    const handleEventDrop = async (eventDropInfo) => {
        const { id } = eventDropInfo.event;
        const updatedDueDate = eventDropInfo.event.start.toISOString().split('T')[0];
        
        setFeedback(prevData => ({...prevData, open: true, message: 'Error Changing Close Date...', type: "error" }))
    }

    return (
        <div className="row" style={{ minHeight: "100vh" }}>
            <div className="col-lg-12">
                <FullCalendar
                    height="85%"
                    aspectRatio={2}
                    plugins={[dayGridPlugin, interactionPlugin]}
                    initialView="dayGridMonth"
                    weekends={false}
                    events={events}
                    eventContent={renderEventContent}
                    editable={true}
                    eventDrop={handleEventDrop}
                    timeZone='UTC'
                />
            </div>
        </div>
    )
}

export default Calendar