import React from 'react';
import { Navigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';
import { Box, CircularProgress } from '@mui/material';

// const Element = ({ child }) => {
//   return (
//   <div>{child}</div>
//   );
// };

// const ProtectedRoute = ({ child }) => {
//   const { user, loading } = useAuth();
//   useEffect(() => {
//   }, [user,loading]);

//   return loading ? null : user ? <Element child={child} /> : <Navigate to="/" />;
// };

// export default ProtectedRoute;
const ProtectedRoute = ({ child }) => {
  const { user, loading } = useAuth();
  if (loading) return <Box display="flex" justifyContent="center" alignItems="center" minHeight="200px"><CircularProgress /></Box>;
  if (!user) return <Navigate to="/" replace />;

  return child;
};

export default ProtectedRoute;