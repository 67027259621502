// import Tasks from "./Tasks"
import CompletionStatus from "./CompletionStatus"
// import SubmitTaskInfo from "./SubmitTaskInfo"
import { useParams } from 'react-router-dom';
import { useEffect, useState } from "react"
// import axios from "axios"
import BaseWrapper from "../BaseWrapper/BaseWrapper"
import Overview from "./Overview";
// import Files from "./Files";
import Lines from "./Lines";
import { Button } from "@mui/material";
import LogsModal from "./LogsModal";
import Feedback from "../Feedback/Feedback"
import { API } from "../../lib/api"

const SingleQuote = () => {
    const { pid } = useParams();
    const [project,setProject] = useState()
    const [logs, setLogs] = useState([])
    const [feedback,setFeedback] = useState({open: false, message: "", type: ""})
    const [logModal, setLogModal] = useState(false)

    const handleClose = () => setLogModal(false)

    useEffect(() => {
      async function getData() {
        const response = await API.Opportunities.FetchById(pid)
        const oppLogsResponse = await API.OpportunityLogs.FetchByOppId(pid)
        setProject(response)
        setLogs(oppLogsResponse)
      }
      getData()
    },[])

    // useEffect(() => {
    //   // Create a cancellation token source for axios
    //   const cancelTokenSource = axios.CancelToken.source();
  
    //   async function fetchTasks() {
    //     try {
    //       const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/tasks/get/${pid}`, {
    //         cancelToken: cancelTokenSource.token, // Set the cancel token
    //       });
  
    //       setTasks(response.data);
    //     } catch (error) {
    //       if (axios.isCancel(error)) {
    //         // Request was canceled (component unmounted), ignore errors
    //         return;
    //       }
  
    //       // Handle other errors
    //       console.error('Error fetching tasks:', error);
    //       setTasks([]);
    //     }
    //   }

    //   async function fetchProject(){
    //     try {
    //         const response = await axios.get(`${process.env.REACT_APP_SERVER_BASE}/projects/sn/${pid}`)
    //         setProject(response.data)
    //     } catch (error) {
    //         console.log(error)
    //     }
    // }
    // fetchProject()
  
    //   fetchTasks();
  
    //   // Cleanup function
    //   return () => {
    //     // Cancel the axios request when the component is unmounted
    //     cancelTokenSource.cancel('Component is unmounted');
    //   };
    // }, [pid]); // Include dependencies in the dependency array if needed

    return (
      <BaseWrapper
        content={
          <>
            <Feedback props={feedback} setFeedback={setFeedback} />
            <div className="row">
            <div style={{display: "flex", flexWrap: "wrap", justifyContent: "center", marginTop: "2em", marginBottom: "2em"}}>
              <Button variant="outlined" onClick={() => setLogModal(true)}>View Opportunity Logs</Button>
            </div>
            <LogsModal logs={logs} open={logModal} handleClose={handleClose} />
            <CompletionStatus project={project} pid={pid} setFeedback={setFeedback} setLogs={setLogs} />
            {/* <Files /> */}
            <Overview project={project} setFeedback={setFeedback} setLogs={setLogs} />
            <Lines setFeedback={setFeedback} project={project} setLogs={setLogs} />
          </div>
          </>
        }
      />
    )
}

export default SingleQuote