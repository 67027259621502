import { useMediaQuery } from '@mui/material';

const ResponsiveColumns = () => {
    // Define the breakpoints
    const isMobile = useMediaQuery('(max-width:600px)');

    return [
        {
            field: "organizationName",
            headerName: "Customer",
            flex: 1,
            cellClassName: "name-column--cell",
            renderCell: ({row: { _id, organizationName } }) => <a href={`/customers/${_id}`}>{organizationName}</a>
          },
            // Conditionally show/hide columns
            ...(isMobile ? [] : [
                {
                    field: "owner",
                    headerName: "Owner",
                    flex: 1,
                    renderCell: ({ row: { owner } }) => owner ? `${owner.firstName} ${owner.lastName}` : 'Unassigned',
                    valueGetter: (params) => `${params.firstName} ${params.lastName}`
                },
                {
                    field: "city",
                    headerName: "City",
                    flex: 1
                },
                {
                    field: "state",
                    headerName: "State/Province",
                    flex: 1,
                    renderCell: ({ row: {state, province }}) => state ? state : province
                },
                {
                    field: "address",
                    headerName: "Address",
                    flex: 1,
                },
                {
                    field: 'countryCode',
                    headerName: "Country",
                    flex: 1
                },
            ]),
            {
                field: "status",
                headerName: "Status",
                flex: 1,
                renderCell: ({ row: { status } }) => {
                    return (
                        <label className={`badge  ${status === 'New' ? 'bg-light-primary' : status === 'Active' ? 'bg-light-success' : status === 'Churned' ? 'bg-light-danger' : status === 'Dormant' ? 'bg-light-secondary' : 'bg-light-warning'}`} style={{minHeight: '75%', minWidth: '75%', padding: "0", lineHeight: '3', fontSize: '14px'}}>{status}</label>
                    );
                }
            }
    ];
};

export default ResponsiveColumns