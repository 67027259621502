// -- Format Events for Schedule Page -- //
export const formatEvents = (response) => {
    return response.map(quote => {
        if (!quote.closeDate) {
            return {}
        }
        let closeDate = new Date(Date.UTC(
            new Date(quote.closeDate).getUTCFullYear(),
            new Date(quote.closeDate).getUTCMonth(),
            new Date(quote.closeDate).getUTCDate()
        ))

        // -- Adding an hour to the date otherwise FullCalendar doesn't recognize the date -- //
        closeDate.setUTCHours(closeDate.getUTCHours() + 1);

        return {
            id: quote._id,
            title: `${quote.name}`,
            start: closeDate.toISOString(),
            end: closeDate.toISOString(),
        }
    })
}