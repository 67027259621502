// DataTable.js
import React from 'react';
import { DataGrid } from "@mui/x-data-grid";
import { Box } from "@mui/material";

const DataTable = ({ rows, columns, handleSelectionChange }) => (
    <div className="row">
        <Box m="20px">
            <Box m="40px 0 0 0" height="75vh">
                <DataGrid
                    checkboxSelection
                    rows={rows}
                    columns={columns}
                    getRowId={(row) => row._id}
                    onRowSelectionModelChange={handleSelectionChange}
                />
            </Box>
        </Box>
    </div>
);

export default DataTable;
