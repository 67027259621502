const CustomersConfig = {

    // -- New Customer Modal | Form Fields -- //
    NewCustomerFields: [
        { name: "organizationName", label: "Organization Name", type: "text", required: true },
        { name: "address", label: "Address", type: "text", required: true },
        { name: "city", label: "City", type: "text" },
        { name: "state", label: "State", type: "text" },
        { name: "province", label: "Province", type: "text" },
        { name: "countryCode", label: "Country", type: "text" },
    ],
    UpdateCustomerFields: [
        { name: "organizationName", label: "Organization Name", type: "text" },
        { name: "address", label: "Address", type: "text" },
        { name: "city", label: "City", type: "text" },
        { name: "state", label: "State", type: "text" },
        { name: "province", label: "Province", type: "text" },
        { name: "countryCode", label: "Country", type: "text" },
        { name: "status", label: "Status", type: 'autocomplete',  options: ['New', 'Active', 'Inactive', 'Pending', 'Dormant', 'Churned']}
    ],
}

export default CustomersConfig