import ReactECharts from 'echarts-for-react';
import { treedata } from '../../lib/chartsData/treedata'
const Tree = () => {
    const option = {
        tooltip: {
          trigger: 'item',
          triggerOn: 'mousemove'
        },
        series: [
          {
            type: 'tree',
            data: [treedata],
            top: '1%',
            left: '7%',
            bottom: '1%',
            right: '20%',
            symbolSize: 7,
            label: {
              position: 'left',
              verticalAlign: 'middle',
              align: 'right',
              fontSize: 9
            },
            leaves: {
              label: {
                position: 'right',
                verticalAlign: 'middle',
                align: 'left'
              }
            },
            emphasis: {
              focus: 'descendant'
            },
            expandAndCollapse: true,
            animationDuration: 550,
            animationDurationUpdate: 750
          }
        ]
      }
  
    return (
        <div className="col-lg-12">
        <div className="card">
            <div className="card-body">
                <div className="d-flex align-items-center justify-content-between">
                    <h5 className="mb-0">Report F</h5>
                </div>
                <ReactECharts
                    option={option}
                    style={{ height: '700px' }}
                    opts={{renderer: 'svg'}} // use svg to render the chart.
                />
            </div>
        </div>
    </div>
    )
}

export default Tree